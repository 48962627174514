<div class="result-dialog-bg">
	<div class="result-dialog">
		<h1>Well done! {{idx.n}}</h1>
		<p>
			You visinted {{citiesAmount}} cities <br>
			with distance of {{fullDistance | number:'1.0-0' }} km.
		</p>
		<!--ul>
			<li *ngFor="let res of results | async">{{res.userId}}: {{res.distance}}</li>
		</ul-->

		<div *ngIf="isAuthorized === false">
			<p>To save your results and compare with others please sign in!</p>
			Sigh In With
			<button (click)="signIn('Google')">Google</button>
			or
			<button (click)="signIn('Facebook')">Facebook</button>
		</div>

		<div *ngIf="user.uid">
			Result will be saved for <img class="result-dialog--photo" src="{{user.photoURL}}"> {{user.displayName}}!
		</div>
		<br>
		<br>

		<button (click)="startGameAgain.emit()">Try again!</button>
		<br>
		<br>


	</div>
</div>
