import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
//import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Component({
	selector: 'result-dialog',
	templateUrl: './result-dialog.component.html',
	styleUrls: ['./result-dialog.component.css']
})
export class ResultDialogComponent implements OnInit {

	@Input() fullDistance: number;
	@Input() citiesAmount: number;
	@Input() gameLevelName: string;
	@Input() _proof: number[];
	@Output() startGameAgain = new EventEmitter();

//	results: Observable<any[]>;
	user: any = {};
	idx: any = {n:1};

	isAuthorized;

	constructor(/*db: AngularFireDatabase*/private firestore: AngularFirestore, private auth: AuthService ) {
		//this.countries = db.list('/Country/countries').valueChanges();
		//this.results = db.list('/results').valueChanges();

		//window.setInterval(() => {
		//	this.idx = { n: this.idx.n+1 };
		//zxc}, 1000);

		auth.getUser().then(user => {
			this.isAuthorized = !!user;
			this.user = user || {};
			//console.log(" user >>> ", user);

			//this.saveResult();
			return this.firestore.collection('gameLevels/'+this.gameLevelName+'/playResults').add({
				userId: this.user.uid,
				time: new Date(),
				distKm: this.fullDistance,
				_proof: JSON.stringify(this._proof)
			});
		});
	}

	ngOnInit() {
	}

	signIn(method) {
		// setInterval(() => {
		// 	this.idx.n = this.idx.n + 1;
		// }, 1000);
		this.auth['signInWith' + method]().then(user => {
			console.log('user 2222 >>> ', user);
			this.user = user || {};
			this.idx = { n: this.idx.n+10000 };
			//this.user.displayName = user.displayName;//; || {};
			//this.user.photoURL = user.photoURL;//; || {};

			//this.saveResult();
		});
	}

	// saveResult() {
	// 	return this.firestore.collection('gameLevels/'+this.gameLevelName+'/playResults').add({
	// 		userId: this.user.uid,
	// 		time: new Date(),
	// 		distKm: this.fullDistance,
	// 		_proof: JSON.stringify(this._proof)
	// 	});
	// }

}
