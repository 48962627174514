<div class="infoBox">
	<div class="text-muted">
		Hello
		<img
			*ngIf="authUser.photoURL"
			class="infoBox-photo"
			src="{{authUser.photoURL}}" />
		<span>{{authUser.displayName || "CommisVoyageur"}}</span> !
	</div>
	<div class="infoBox-discr text-muted">Try to visit all cities with the shortest fly distance...</div>

	<table>
		<tr>
			<td class="text-muted">Direction:</td>
			<td class="infoBox-cities">
				<b>{{cityFrom}}</b>
				<span [ngClass]="{'text-blink': cityTo && _lockedUserActions}">&nbsp;&rArr;&nbsp;</span>
				<b [ngClass]="{'text-blink': !cityTo}">{{cityTo || 'pick city to fly...'}}</b>
			</td>
		</tr>
		<tr>
			<td class="text-muted">Visited:</td>
			<td><b class="infoBox-val">{{visitedCitisAmount}}</b> of <b class="infoBox-val">{{citiesAmount}}</b> cities</td>
		</tr>
		<tr>
			<td class="text-muted">Total:</td>
			<td style="width: 100%;">
				<b style="display: inline-block; width: 42%;">
					<span class="infoBox-val">{{ fullDistanceFormatted }}</span> km
				</b>
				<!-- b>
					<span class="infoBox-val"><span class="text-muted">/</span> {{ fullDistanceFormattedMiles }}</span> miles
				</b -->
			</td>
		</tr>
		<tr>
			<td></td>
			<td style="width: 100%;">
				<b><span class="infoBox-val">{{ fullDistanceFormattedMiles }}</span> miles</b>
			</td>
		</tr>
	</table>

</div>

<div #cvmap id="CVmap"></div>

<!--chat></chat-->

<!-- +{{ nextCityDistanceFormatted }} km 		| +{{ nextCityDistanceFormattedMiles }} miles 	|   -->

<!--hello-dialog></hello-dialog-->

<result-dialog
	*ngIf = "visitedCitisAmount > 0 && visitedCitisAmount === citiesAmount"
	[fullDistance] = "fullDistance"
	[citiesAmount] = "citiesAmount"
	[gameLevelName] = "'Europe30'"
	[_proof] = "_proof"
	(startGameAgain) = "initStartingState(cities)"
	[@fadeInOut]
></result-dialog>

<!-- DEBUG -->
<!--div style="position:absolute; z-index:1000; top:0; left:0;">[{{visitedCitisAmount === citiesAmount}}]</div-->
