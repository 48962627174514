// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	firebase: {
		apiKey: "AIzaSyBpdnOUPQ1Yinoz8iaolNU6BBh2eQ9MmO8",
		authDomain: "commisvoyageur-1521998805798.firebaseapp.com",
		databaseURL: "https://commisvoyageur-1521998805798.firebaseio.com",
		projectId: "commisvoyageur-1521998805798",
		storageBucket: "commisvoyageur-1521998805798.appspot.com",
		messagingSenderId: "274601862574",
		appId: "1:274601862574:web:a80377ef498bb0b541143f",
		measurementId: "G-SRM8D06KF3"
	}
};
