import { Component, ViewChild } from '@angular/core';
import {style, state, animate, transition, trigger} from '@angular/animations';

//import { } from '@types/googlemaps';
// import {} from 'googlemaps';


// import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import { AuthService } from './auth.service';


interface City {
	city: string,
	loc: {
		lat: number,
		lng: number
	},
	img: string,
	country: string
}

const milesPerKm = 0.62137119224;


@Component({
	selector: 'app-commisvoyageur',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	animations: [trigger('fadeInOut', [
		transition(':enter', [
			style({transform: 'translateX(100%)', opacity: 0}),
			animate(500, style({transform: 'translateX(0)', opacity: 1}))
		]),
		transition(':leave', [
			style({transform: 'translateX(0)', opacity: 1}),
			animate(500, style({transform: 'translateX(100%)', opacity: 0}))
		])
	])]
})

export class AppComponent {
	//authUser: Object = {};
	authUser: any = {};
	title = 'CommisVoyageur';
	fullDistance: number;
	fullDistanceFormatted: string;
	fullDistanceFormattedMiles: string;
	nextCityDistanceFormatted: string;
	nextCityDistanceFormattedMiles: string;
	cityToClose: any;
	cityFrom: string;
	cityTo: string;
	citiesAmount: number;
	visitedCitisAmount: number;
	citiesQueue: any[];
	_proof: number[];

	@ViewChild('cvmap', {static: true}) gmapElement: any;
	map: any;// google.maps.Map;
	cities: City[];
	allInfoWindows: google.maps.InfoWindow[] = [];
	airplane: any;
	Kyiv = {"lat":50.4501,"lng":30.5234};
	London = {"lat":51.5073509,"lng":-0.1277583};
	NewYork = {"lat":40.7127753,"lng":-74.0059728};

	_lockedUserActions = false;

	//constructor(private http: HttpClient) {}
	//constructor(private user: UserService, private auth: AuthService) {
	constructor(private user: UserService, private auth: AuthService) {
		auth.getUser().then(authUser => this.authUser = authUser || {});
	}

	ngOnInit() {

		this.map = new google.maps.Map(this.gmapElement.nativeElement, <any>{
			//center: {lat: 0, lng: 0},
			center: {lat:47.9027835,lng:12.4963655},
			//center: new google.maps.LatLng(0,0),
			zoom: 5,
			//mapTypeId: 'hybrid',
			mapTypeId: google.maps.MapTypeId.HYBRID,

	//			mapTypeId: google.maps.MapTypeId.SATELLITE,
	//			mapTypeId: google.maps.MapTypeId.HYBRID,
	//			mapTypeId: google.maps.MapTypeId.ROADMAP,
	//			mapTypeId: google.maps.MapTypeId.TERRAIN,

			//disableDefaultUI: true,

			disableDoubleClickZoom: true,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: true,
			panControl: false,
			scrollwheel:  false,
			zoomControl: true,
		});

		//this.http.get('assets/cities-info/_list.json').subscribe( (data: City[]) => {
		//this.http.get('assets/cities-info/_list-europe.json').subscribe( (data: City[]) => {
		this.user.cities.subscribe( (data: City[]) => {
			this.cities = data;
			const startCity = this.initStartingState(this.cities);
			this.putAirplane(startCity.loc);
		});

		// console.log('google.maps.SymbolPath >>>> ',google.maps.SymbolPath);
	}

	initStartingState(cities) {
		console.log(' >>>> initStartingState(cities) ');
		this.fullDistance = 0;
		this.fullDistanceFormatted = '0';
		this.fullDistanceFormattedMiles = '0';
		this.nextCityDistanceFormatted = '0';
		this.nextCityDistanceFormattedMiles = '0';
		this.visitedCitisAmount = 0;
		this.citiesQueue = [];
		this.citiesAmount = cities.length;
		this._proof = [];
		this.putCities();
		// this.drawRoute();
		//this.putAirplane(this.Kyiv);
		const startCity = cities[0];
		this.cityFrom = startCity.city;
		this.cityTo = '';
		//this.putAirplane(startCity.loc);
		this.map.setCenter({lat:startCity.loc.lat, lng:startCity.loc.lng});
		//this.flyTo(this.Kyiv);
		return startCity;
	}

	putAirplane(location) {
		this.airplane = new google.maps.Marker(<any>{
			position: location,
			animation: google.maps.Animation.DROP,
			map: this.map,
			icon: 'assets/images/airplane-right.png',
			zIndex: 0
		});
	}

	putCities() {
		this.cities.forEach((city, cityIndex) => {
			setTimeout(() => {
/*
				const marker: google.maps.Marker = new google.maps.Marker(<any>{
					position: city.loc,
					map: this.map,
					animation: google.maps.Animation.DROP,
					title: city.city,
					city: city.city,
					country: city.country,
					// label: city.city[0]
				});
*/
				const marker: any = new google.maps.Marker(<any>{
					position: city.loc,
					map: this.map,
					animation: google.maps.Animation.DROP,
					title: city.city,
					city: city.city,
					country: city.country,
					zIndex: 10
					// label: city.city[0]
				});

				marker.infoWindow = new google.maps.InfoWindow(<any>{
					content: `
						<h3>${city.city}<br>${city.country}</h3>
						<img class="infoWindow-img" src="assets/cities-info/160x120-${city.img}" />
					`,
					maxWidth: 200
				});

				if (city.city === this.cityFrom) {
					marker.setIcon('assets/images/icon-finish.png');
					marker.zIndex = -1;
					marker._isFinish = true;
				}

				this.allInfoWindows.push(marker.infoWindow);

				marker.addListener('click', () => {

					// if (!this._lockedUserActions) {
					// 	this._lockedUserActions = true;
					// 	this.allInfoWindows.forEach(iw => iw.close());
					// 	this.cityFrom = this.cityTo || this.cityFrom;
					// 	this.cityTo = marker.city;
					// 	this.airplane.zIndex = 20;
					// 	this.flyTo(marker.getPosition(), () => {
					// 		infoWindow.open(this.map, marker);
					// 		marker.setIcon('assets/images/empty.png');
					// 		this.visitedCitisAmount++;
					// 		this._lockedUserActions = false;
					// 		this.airplane.zIndex = 0;
					// 		setTimeout(() => {
					// 			infoWindow.close();
					// 			marker.setMap(null);
					// 		}, 1000);
					// 	});
					// }

					this._proof.push(cityIndex);

					if (!marker._isVisited && (
						this.citiesQueue.indexOf(marker) === -1 && !marker._isFinish && !marker._isVisited
						||
						marker._isFinish && this.visitedCitisAmount === this.cities.length - 1
					)) {
						this.citiesQueue.push(marker);
						marker.setIcon('assets/images/icon-wait.png');
						this.closeCityInfo();
						this.tryRunFlying();
						console.log(this.citiesQueue.length , !this._lockedUserActions);
					}
				});

			}, Math.round(Math.random() * 3000));
		});
	}

	tryRunFlying() {
		if (this.citiesQueue.length && !this._lockedUserActions) {
			this._lockedUserActions = true;
			// this.allInfoWindows.forEach(iw => iw.close());
			this.cityFrom = this.cityTo || this.cityFrom;
			this.cityTo = this.citiesQueue[0].city;
			this.airplane.zIndex = 20;
			this.flyTo(this.citiesQueue[0].getPosition(), () => {
				if (this.citiesQueue.length === 1) {
					this.citiesQueue[0].infoWindow.open(this.map, this.citiesQueue[0]);
				}
				this.citiesQueue[0].setIcon('assets/images/empty.png');
				this.citiesQueue[0]._isVisited = true;
				this.visitedCitisAmount++;
				this.airplane.zIndex = 0;
				setTimeout(() => {
					//this.citiesQueue[0].infoWindow.close();
					//this.citiesQueue[0].setMap(null);
					this.cityToClose = this.citiesQueue[0];
					this.citiesQueue.shift();
					this._lockedUserActions = false;
					if (this.citiesQueue.length) {
						this.tryRunFlying();
					}
				}, 100);
				setTimeout(this.closeCityInfo.bind(this), 1000);
			});
		}
	}

	closeCityInfo() {
		if (this.cityToClose) {
			this.cityToClose.infoWindow.close();
			this.cityToClose.setMap(null);
			delete this.cityToClose;
		}
	}

	drawRoute() {
		let cirleSymbol = {
			path: google.maps.SymbolPath.CIRCLE,
			scale: 8,
			strokeColor: '#393'
		};

		const dashedSymbol = {
			path: 'M 0,-1 0,1',
			strokeOpacity: 1,
			scale: 4,
			strokeColor: '#ffffff',
			opacity: 0.5
		};

		const line = new google.maps.Polyline({
			path: [this.Kyiv, this.London],
			strokeOpacity: 0,
			icons: [
			//{
			//	icon: cirleSymbol,
			//	offset: '100%'
			//}
			 {
				icon: dashedSymbol,
				offset: '0',
				repeat: '20px'
			}
			],
			map: this.map
		});


	}

	flyTo(destination, onArrive) {
		const animationFrames = [];
		const steps: number = 100;
		const airplanePos = this.airplane.getPosition();
		const oldPos = {
			lat: typeof airplanePos.lat === 'function' ? airplanePos.lat() : airplanePos.lat,
			lng: typeof airplanePos.lng === 'function' ? airplanePos.lng() : airplanePos.lng
		};
		const newPos = {
			lat: typeof destination.lat === 'function' ? destination.lat() : destination.lat,
			lng: typeof destination.lng === 'function' ? destination.lng() : destination.lng
		};
		const diffLng = Math.abs(newPos.lng - oldPos.lng);
		const direction = diffLng < 180 && oldPos.lng < newPos.lng || diffLng >= 180 && oldPos.lng >= newPos.lng ? 1 : -1;
		const diff = {
			lat: (newPos.lat - oldPos.lat),
			lng: direction * Math.min(diffLng, 360-diffLng)
		};
		const delta = {
			lat: diff.lat / steps,
			lng: diff.lng / steps
		};
		const nextCityDistance = this._getDistance(oldPos, newPos);
		this.nextCityDistanceFormatted = Math.round(nextCityDistance).toLocaleString();
		this.nextCityDistanceFormattedMiles = Math.round(nextCityDistance * milesPerKm).toLocaleString();
		const deltaKM = nextCityDistance / steps;

		this.airplane.setIcon(`assets/images/airplane-${direction > 0 ? 'right' : 'left'}.png`);

		//const hightArg = 15/this.map.zoom;
		const hightArg = Math.min(25, 75/Math.pow(this.map.zoom,2));

		for (let i = 0; i < steps; i++) {
			animationFrames.push({
//				lat: oldPos.lat + i*delta.lat  + Math.abs(diff.lat) * (- Math.pow(2*i/steps - 1, 2) + 1),
				lat: oldPos.lat + i*delta.lat  +  hightArg * (- Math.pow(2*i/steps - 1, 6) + 1),
				lng: (oldPos.lng + i*delta.lng + 180) % 360 - 180
			});
		}

		this._playAnimationFrames(animationFrames, deltaKM, onArrive);


// zoom - arg
//0
//1 - 8
//2   7
//3 - 6
//4 - 5
//5 - 3 !!!
//6 - 2
//7 - 1
//               3 * 5/this.map.zoom

	}

	private _playAnimationFrames(animationFrames, deltaKM, onFinish) {
		let nextFrame;
		setTimeout(()=>{
			nextFrame = animationFrames.shift();
			this.airplane.setPosition(nextFrame);

			this.fullDistance += deltaKM;
			this.fullDistanceFormatted = Math.round(this.fullDistance).toLocaleString();
			this.fullDistanceFormattedMiles = Math.round(this.fullDistance * milesPerKm).toLocaleString();

			if (animationFrames.length) {
				this._playAnimationFrames(animationFrames, deltaKM, onFinish);
			} else {
				onFinish();
			}
		}, 5);

	}

	private _getDistance(p1, p2) {
		const rad = (x) => x * Math.PI / 180;
		const R = 6378137; // Earth’s mean radius in meter
		const dLat = rad(p2.lat - p1.lat);
		const dLong = rad(p2.lng - p1.lng);
		const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
				Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
				Math.sin(dLong / 2) * Math.sin(dLong / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		return R * c / 1000; // returns the distance in kilometers
	};

}
