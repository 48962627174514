import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	public name;
	public cities;

	constructor(private http: HttpClient) {
		this.cities = this.http.get('assets/cities-info/_list-europe.json');
	}

}
