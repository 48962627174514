import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireModule } from 'angularfire2';
//import { AngularFireDatabaseModule } from 'angularfire2/database'; //???
// import { AngularFireModule } from '@angular/fire';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';


import { environment } from './../environments/environment';



import { AppComponent } from './app.component';
//import { ChatComponent } from './chat/chat.component';

import { HttpClientModule } from '@angular/common/http';
//import { HelloDialogComponent } from './hello-dialog/hello-dialog.component';
import { ResultDialogComponent } from './result-dialog/result-dialog.component';

import { UserService } from './user.service';


@NgModule({
	declarations: [
		AppComponent,
//    ChatComponent,
//    HelloDialogComponent,
		ResultDialogComponent //,
//	UserService
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AngularFireModule.initializeApp(environment.firebase ),
		// AngularFireDatabaseModule ???
		AngularFirestoreModule, // imports firebase/firestore, only needed for database features
		AngularFireAuthModule, // imports firebase/auth, only needed for auth features
		//UserService
	],
	providers: [UserService],
	bootstrap: [AppComponent]
})
export class AppModule { }
