import { Injectable } from '@angular/core';

import { auth } from 'firebase/app';
//import { AngularFireAuth } from "@angular/fire/auth";
// import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from 'angularfire2/auth';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

	constructor(public firebase: AngularFireAuth /* Inject Firebase auth service */) {
	}

	signInWithGoogle() {
		return this.signIn(new auth.GoogleAuthProvider());
	}

	signInWithFacebook() {
		return this.signIn(new auth.FacebookAuthProvider());
	}

	signIn(provider) {
		return this.firebase.auth.signInWithPopup(provider)
		.then((result) => {
			console.log('user data >>> ', result.user);
			return result.user;
		})
		.catch(async err => {
			// Discription here: https://stackoverflow.com/questions/44015751/firebase-js-api-auth-account-exists-with-different-credential
			if (err.email && err.credential && err.code === 'auth/account-exists-with-different-credential') {
				const linkedProvider = new auth.GoogleAuthProvider();
				linkedProvider.setCustomParameters({ login_hint: err.email });
				const result = await this.firebase.auth.signInWithPopup(linkedProvider);
				result.user.linkWithCredential(err.credential);

				return result.user;
			}

			// Handle errors...
			console.error(err.message || err.toString());
			alert('Something went wrong :( \nError with authorization');
		});
	}

	getUser() {
		return new Promise(resolve => {
			if (this.firebase.auth.currentUser) {
				console.log('getUser() 111 ', this.firebase.auth.currentUser);
				resolve(this.firebase.auth.currentUser);
			} else {
				this.firebase.auth.onAuthStateChanged(function(user) {
					console.log('getUser() 222 ', user);
					resolve(user);
				});
			}
		});
	}

	// Sign out
	signOut() {
		return this.firebase.auth.signOut().then(() => {
			console.log('Logged out');
			//localStorage.removeItem('user');
		});
	}


  	/* Setting up user data when sign in with username/password,
      sign up with username/password and sign in with social auth
      provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
      // SetUserData(user) {
        // const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        // const userData: User = {
        //   uid: user.uid,
        //   email: user.email,
        //   displayName: user.displayName,
        //   photoURL: user.photoURL,
        //   emailVerified: user.emailVerified
        // }
        // return userRef.set(userData, {
        //   merge: true
        // })
      // }






	    	// // Returns true when user is looged in and email is verified
	        // get isLoggedIn(): boolean {
	        //   const user = JSON.parse(localStorage.getItem('user'));
	        //   return (user !== null && user.emailVerified !== false) ? true : false;
	        // }

	    	// Auth logic to run auth providers

	//
	//   doGoogleLogin(){
  	// 	return new Promise<any>((resolve, reject) => {
  	// 		let provider = new firebase.auth.GoogleAuthProvider();
  	// 		provider.addScope('profile');
  	// 		provider.addScope('email');
  	// 		this.afAuth.auth
  	// 		.signInWithPopup(provider)
  	// 		.then(res => {
  	// 			resolve(res);
  	// 		})
  	// 	})
  	// }
	//
  	// doFacebookLogin() {
  	// 	return new Promise<any>((resolve, reject) => {
  	// 		let provider = new firebase.auth.FacebookAuthProvider();
  	// 		this.afAuth.auth
  	// 			.signInWithPopup(provider)
  	// 			.then(res => {
  	// 				resolve(res);
  	// 			}, err => {
  	// 				console.log(err);
  	// 				reject(err);
  	// 			})
  	// 	})
  	// }

}
